import { reduce } from 'ramda';
import sortByYear from '../../../utils/sortByYear';

export const formatSignalingValue = partnership => {
  const {
    indicators: { signalingValue },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      acc[year] = {
        label: partnership.shortName,
        values: [
          {
            label: 'Signaleringsvermogen',
            value: Math.round(signalingValue[year].signalValue),
          },
        ],
      };

      return acc;
    },
    {},
    sortByYear(Object.keys(signalingValue))
  );
  return data;
};

export const formatNationalSignalingValue = (signalingValue, sector) => {
  const data = reduce(
    (acc, year) => {
      acc = {
        label: 'Landelijk gemiddelde',
        values: [
          {
            label: 'Signaleringsvermogen',
            striped: true,
            opacity: 0.8,
            year,
            value: Math.round(
              Number(signalingValue[`_${year}`][sector].signalValue)
            ),
          },
        ],
      };
      return acc;
    },
    {},
    sortByYear(Object.keys(signalingValue).map(x => x.replace('_', '')))
  );

  return data;
};
