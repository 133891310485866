import { reduce } from 'ramda';
import sortByYear from '../../../utils/sortByYear';

const formatResistance = n => (n * 100).toFixed(2);

export const formatReservePosition = partnership => {
  const {
    indicators: { reservePosition },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      acc[year] = [
        {
          label: partnership.shortName,
          values: [
            {
              label: 'Weerstandsvermogen',
              value: Number(
                reservePosition[year]
                  ? reservePosition[year].resistance
                  : reservePosition[`_${year}`].resistance
              ),
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(reservePosition).map(x => x.replace('_', '')))
  );
  return data;
};

export const formatNationalReservePosition = (
  nationalReservePosition,
  sector
) => {
  const data = reduce(
    (acc, year) => {
      acc[year.replace('_', '')] = [
        {
          label: 'Landelijk gemiddelde',
          values: [
            {
              label: 'Weerstandsvermogen',
              striped: true,
              opacity: 0.8,
              year,
              value: Number(
                formatResistance(
                  nationalReservePosition[year][sector].calculatedResistance
                )
              ),
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(nationalReservePosition))
  );
  return data;
};
