import { reduce } from 'ramda';
import sortByYear from '../../../utils/sortByYear';

export const formatReservePosition = partnership => {
  const {
    indicators: { reservePosition },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      acc[year] = [
        {
          label: partnership.shortName,
          values: [
            {
              label: 'Weerstandsvermogen',
              value: reservePosition[year]
                ? reservePosition[year].resistance
                : reservePosition[`_${year}`].resistance,
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(reservePosition).map(x => x.replace('_', '')))
  );
  return data;
};

export const formatEquity = partnership => {
  const {
    indicators: { equityIndicator },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      acc[year] = [
        {
          label: partnership.shortName,
          values: [
            {
              label: 'Eigen vermogen',
              year,
              value: Math.round(equityIndicator[year].publicEquity),
            },
            {
              label: 'Signaleringswaarde',
              year,
              value: Math.round(equityIndicator[year].signalValue),
            },
            {
              label: 'Verschil',
              year,
              value: Math.round(equityIndicator[year].excessiveReserve),
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(equityIndicator).map(x => x.replace('_', '')))
  );
  return data;
};

export const formatNationalEquity = (equity, sector) => {
  const data = reduce(
    (acc, year) => {
      acc[year.replace('_', '')] = [
        {
          label: 'Landelijk gemiddelde',
          values: [
            {
              label: 'Eigen vermogen',
              striped: true,
              year,
              value: Math.round(
                equity[year][sector].publicEquity /
                  equity[year][sector].swvTotal
              ),
            },
            {
              label: 'Signaleringswaarde',
              striped: true,
              year,
              value: Math.round(
                equity[year][sector].signalValue / equity[year][sector].swvTotal
              ),
            },
            {
              label: 'Verschil',
              striped: true,
              year,
              value: Math.round(
                equity[year][sector].excessiveReserve /
                  equity[year][sector].swvTotal
              ),
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(equity))
  );

  return data;
};
