import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import Legend from '@greenberry/salal/components/Legend';
import theme from '@greenberry/salal/theme';
import getLatestYear from '../../../utils/getLatestYear';
import { withIndicators } from '../../../state/selectedForComparison';
import IndicatorSection, { OVERVIEW } from '../../IndicatorSection';
import IndicatorLoading from '../../IndicatorLoading';
import { formatEquity, formatNationalEquity } from './utils';

const INDICATOR_NAME = 'equity';

const Equity = ({ equityNational, dataSource, ...props }) => {
  const partnerships = useRecoilValueLoadable(withIndicators);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Laden…"
      />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Geen samenwerkingsverbanden geselecteerd"
      />
    );

  const currentSector = partnerships.contents[0].sector;

  const formattedIndicator = partnerships.contents.map(formatEquity);
  const latestYear = formattedIndicator.map(getLatestYear);
  const nationalAsPartnership = formatNationalEquity(
    equityNational,
    currentSector
  );

  const overviewData = [
    ...latestYear[0],
    ...latestYear[1],
    ...getLatestYear(nationalAsPartnership),
  ];
  return (
    <>
      <IndicatorSection
        selectedView={OVERVIEW}
        indicator={INDICATOR_NAME}
        disableViewToggle
        {...props}
      >
        <GroupedBarChart
          data={overviewData}
          xKey="label"
          height={450}
          unit="EUR"
          tooltip
        />
        <Legend
          alignment={'float'}
          source={dataSource}
          data={[
            { label: 'Eigen vermogen', color: theme.color('chart.1') },
            {
              label: 'Signaleringswaarde',
              color: theme.color('chart.2'),
            },
            {
              label: 'Verschil',
              color: theme.color('chart.3'),
            },
          ]}
        />
      </IndicatorSection>
    </>
  );
};

const sectorShape = PropTypes.shape({
  excessiveReserve: PropTypes.number.isRequired,
  excessiveReservePercent: PropTypes.number.isRequired,
  publicEquity: PropTypes.number.isRequired,
  signalValue: PropTypes.number.isRequired,
  swvTotal: PropTypes.number.isRequired,
}).isRequired;

export const nationalReservePositionShape = PropTypes.shape({
  PO: sectorShape,
  VO: sectorShape,
});

Equity.propTypes = {
  dataSource: PropTypes.string.isRequired,
  equityNational: PropTypes.shape({
    _2017: nationalReservePositionShape,
    _2018: nationalReservePositionShape,
    _2019: nationalReservePositionShape,
  }).isRequired,
};

export default Equity;
