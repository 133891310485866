import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import { nationalReservePositionShape } from '../../components/Comparison/ReservePosition';
import { signalingValueShape } from '../../components/Comparison/SignalingValue';

import ComparisonLayout from '../../components/ComparisonLayout';
import ReservePosition from '../../components/Comparison/ReservePosition';
import SignalingValue from '../../components/Comparison/SignalingValue';
import Equity from '../../components/Comparison/Equity';
// import RelativeReserve from '../../components/Comparison/RelativeReserve';

const ComparisonFinancial = ({
  data: {
    copy,
    nationalReservePosition,
    // nationalStudentCount,
    signalingValue,
    equityNational,
    equitySource,
    reservePositionSource,
    signalingValueSource,
    // studentCountPrognosis,
  },
}) => (
  <ComparisonLayout seoMetaTags={copy.seoMetaTags}>
    <Section size="2">
      <Introduction title={copy.title} text={copy.intro} />
    </Section>
    <Equity
      title={copy.equityTitle}
      body={copy.equityBody}
      equityNational={equityNational.equityNational}
      dataSource={`${equitySource.source} ${equitySource.date}`}
    />
    {/* TODO add back when Backend and data are correct */}
    {/* <RelativeReserve
      title={copy.relativeReserveTitle}
      body={copy.relativeReserveBody}
      equityNational={equityNational.equityNational}
      dataSource={`${equitySource.source} ${equitySource.date}`}
      nationalStudentCount={nationalStudentCount}
      studentCountPrognosis={studentCountPrognosis}
    /> */}
    <ReservePosition
      title={copy.reservePositionTitle}
      body={copy.reservePositionBody}
      nationalReservePosition={nationalReservePosition.reservePositionNational}
      dataSource={`${reservePositionSource.source} ${reservePositionSource.date}`}
    />
    <SignalingValue
      title={copy.signalingValueTitle}
      body={copy.signalingValueBody}
      signalingValue={signalingValue.signalingValue}
      dataSource={`${signalingValueSource.source} ${signalingValueSource.date}`}
    />
  </ComparisonLayout>
);

ComparisonFinancial.propTypes = {
  data: PropTypes.shape({
    copy: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      supportBudgetTitle: PropTypes.string.isRequired,
      supportBudgetBody: PropTypes.string.isRequired,
      settlementsTitle: PropTypes.string.isRequired,
      settlementsBody: PropTypes.string.isRequired,
      reservePositionTitle: PropTypes.string.isRequired,
      reservePositionBody: PropTypes.string.isRequired,
      signalingValueTitle: PropTypes.string.isRequired,
      signalingValueBody: PropTypes.string.isRequired,
      equityTitle: PropTypes.string.isRequired,
      equityBody: PropTypes.string.isRequired,
      relativeReserveTitle: PropTypes.string.isRequired,
      relativeReserveBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({
        tags: PropTypes.array,
      }).isRequired,
    }).isRequired,
    reservePositionSource: PropTypes.shape({
      source: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
    equitySource: PropTypes.shape({
      source: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
    signalingValueSource: PropTypes.shape({
      source: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
    equityNational: PropTypes.any.isRequired,
    signalingValue: PropTypes.shape({
      signalingValue: PropTypes.shape({
        _2019: signalingValueShape,
      }).isRequired,
    }),
    nationalStudentCount: PropTypes.object.isRequired,
    nationalReservePosition: PropTypes.shape({
      reservePositionNational: PropTypes.shape({
        _2014: nationalReservePositionShape,
        _2015: nationalReservePositionShape,
        _2016: nationalReservePositionShape,
        _2017: nationalReservePositionShape,
        _2018: nationalReservePositionShape,
      }).isRequired,
    }),
    studentCountPrognosis: PropTypes.array.isRequired,
  }).isRequired,
};

export default ComparisonFinancial;

export const query = graphql`
  query getComparisonFinancial {
    reservePositionSource: datoCmsDataSource(
      indicator: { eq: "Reservepositie" }
    ) {
      source
      date
    }
    equitySource: datoCmsDataSource(indicator: { eq: "Eigen vermogen" }) {
      source
      date
    }
    signalingValueSource: datoCmsDataSource(
      indicator: { eq: "Signaleringswaarden" }
    ) {
      source
      date
    }

    copy: datoCmsComparisonFinancial {
      title
      intro
      supportBudgetTitle
      supportBudgetBody
      settlementsTitle
      settlementsBody
      reservePositionTitle
      reservePositionBody
      signalingValueTitle
      signalingValueBody
      equityTitle
      equityBody
      relativeReserveTitle
      relativeReserveBody
      seoMetaTags {
        tags
      }
    }
    signalingValue: nationalIndicator(key: { eq: "signalingValue" }) {
      signalingValue {
        _2020 {
          PO {
            publicEquity
            signalValue
            netGain
            excessiveReservePercent
            excessiveReserve
          }
          VO {
            publicEquity
            signalValue
            netGain
            excessiveReservePercent
            excessiveReserve
          }
        }
      }
    }

    equityNational: nationalIndicator(key: { eq: "equityNational" }) {
      equityNational {
        _2020 {
          PO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
          VO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
        }
        _2019 {
          PO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
          VO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
        }
        _2018 {
          PO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
          VO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
        }
        _2017 {
          PO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
          VO {
            publicEquity
            signalValue
            excessiveReserve
            excessiveReservePercent
            swvTotal
          }
        }
      }
    }
    nationalReservePosition: nationalIndicator(
      key: { eq: "reservePositionNational" }
    ) {
      reservePositionNational {
        _2017 {
          PO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
          VO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
        }
        _2018 {
          PO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
          VO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
        }
        _2019 {
          PO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
          VO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
        }
        _2020 {
          PO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
          VO {
            calculatedResistance
            equity
            governmentGrants
            materialFixedAssets
            resistance
          }
        }
      }
    }
    nationalStudentCount: nationalIndicator(key: { eq: "studentCount" }) {
      studentCount {
        _2020_2021 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2019_2020 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2018_2019 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2017_2018 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2016_2017 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2015_2016 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2014_2015 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
      }
    }
  }
`;
