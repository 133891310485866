import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import Legend from '@greenberry/salal/components/Legend';
import theme from '@greenberry/salal/theme';
import getLatestYear from '../../../utils/getLatestYear';
import { withIndicators } from '../../../state/selectedForComparison';
import IndicatorSection, { OVERVIEW } from '../../IndicatorSection';
import IndicatorLoading from '../../IndicatorLoading';
import { formatReservePosition, formatNationalReservePosition } from './utils';

const INDICATOR_NAME = 'reservePosition';

const ReservePosition = ({ nationalReservePosition, dataSource, ...props }) => {
  const partnerships = useRecoilValueLoadable(withIndicators);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Laden…"
      />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Geen samenwerkingsverbanden geselecteerd"
      />
    );

  const currentSector = partnerships.contents[0].sector;

  const formattedIndicator = partnerships.contents.map(formatReservePosition);
  const latestYear = formattedIndicator.map(getLatestYear);
  const nationalAsPartnership = formatNationalReservePosition(
    nationalReservePosition,
    currentSector
  );

  const overviewData = [
    ...latestYear.flatMap(year => year),
    ...getLatestYear(nationalAsPartnership),
  ];

  return (
    <>
      <IndicatorSection
        selectedView={OVERVIEW}
        indicator={INDICATOR_NAME}
        disableViewToggle
        {...props}
      >
        <GroupedBarChart
          data={overviewData}
          xKey="label"
          height={450}
          unit="percentage"
          tooltip
        />
        <Legend
          alignment={'float'}
          source={dataSource}
          data={[
            { label: 'Weerstandsvermogen', color: theme.color('chart.1') },
          ]}
        />
      </IndicatorSection>
    </>
  );
};

const sectorShape = PropTypes.shape({
  calculatedResistance: PropTypes.number.isRequired,
  equity: PropTypes.number.isRequired,
  governmentGrant: PropTypes.number.isRequired,
  materialFixedAssets: PropTypes.number.isRequired,
  resistance: PropTypes.number.isRequired,
}).isRequired;

export const nationalReservePositionShape = PropTypes.shape({
  PO: sectorShape,
  VO: sectorShape,
});

ReservePosition.propTypes = {
  dataSource: PropTypes.string.isRequired,
  nationalReservePosition: PropTypes.shape({
    _2014: nationalReservePositionShape,
    _2015: nationalReservePositionShape,
    _2016: nationalReservePositionShape,
    _2017: nationalReservePositionShape,
    _2018: nationalReservePositionShape,
    _2019: nationalReservePositionShape,
  }).isRequired,
};

export default ReservePosition;
