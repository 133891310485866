import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import Legend from '@greenberry/salal/components/Legend';
import theme from '@greenberry/salal/theme';
import getLatestYear from '../../../utils/getLatestYear';
import { withIndicators } from '../../../state/selectedForComparison';
import IndicatorSection, { OVERVIEW } from '../../IndicatorSection';
import IndicatorLoading from '../../IndicatorLoading';
import { formatSignalingValue, formatNationalSignalingValue } from './utils';

const INDICATOR_NAME = 'signalingValue';

const SignalingValue = ({ signalingValue, dataSource, ...props }) => {
  const partnerships = useRecoilValueLoadable(withIndicators);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Laden…"
      />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Geen samenwerkingsverbanden geselecteerd"
      />
    );

  const currentSector = partnerships.contents[0].sector;

  const formattedIndicator = partnerships.contents.map(formatSignalingValue);

  const latestYear = formattedIndicator.map(getLatestYear);
  const SignalingValueAsPartnership = formatNationalSignalingValue(
    signalingValue,
    currentSector
  );

  const overviewData = [...latestYear, SignalingValueAsPartnership];
  return (
    <>
      <IndicatorSection
        selectedView={OVERVIEW}
        indicator={INDICATOR_NAME}
        disableViewToggle
        {...props}
      >
        <GroupedBarChart
          data={overviewData}
          xKey="label"
          height={450}
          unit="EUR"
          tooltip
        />
        <Legend
          alignment={'float'}
          source={dataSource}
          data={[
            { label: 'Signaleringswaarde', color: theme.color('chart.1') },
          ]}
        />
      </IndicatorSection>
    </>
  );
};

const sectorShape = PropTypes.shape({
  publicEquity: PropTypes.number.isRequired,
  signalValue: PropTypes.number.isRequired,
  netGain: PropTypes.number.isRequired,
  excessiveReservePercent: PropTypes.number.isRequired,
  excessiveReserve: PropTypes.number.isRequired,
}).isRequired;

export const signalingValueShape = PropTypes.shape({
  PO: sectorShape,
  VO: sectorShape,
});

SignalingValue.propTypes = {
  dataSource: PropTypes.string.isRequired,
  signalingValue: PropTypes.shape({
    _2019: signalingValueShape,
  }).isRequired,
};

export default SignalingValue;
